// メイン画面下部に表示するNavbar

<template>
  <div class="navbar">
    <router-link
      class="link"
      :to="{ name: 'Home' }"
    >
      <svg-image
        class="icon"
        name="menu_home"
      />
      <div class="name">
        ホーム
      </div>
    </router-link>

    <router-link
      class="link"
      :to="{ name: 'Order' }"
    >
      <svg-image
        class="icon"
        name="menu_order"
      />
      <div class="name">
        弁当注文
      </div>
    </router-link>

    <router-link
      class="link"
      :to="{ name: 'History' }"
    >
      <svg-image
        class="icon"
        name="menu_history"
      />
      <div class="name">
        注文履歴
      </div>
    </router-link>
<!-- 
    <router-link
      class="link"
      :to="{ name: 'Others' }"
    >
      <svg-image
        class="icon"
        name="menu_others"
      />
      <div class="name">
        その他
      </div>
    </router-link>

     -->
  </div>
</template>

<script>
import SvgImage from "./SvgImage.vue";
export default {
  components: { SvgImage },
  setup() {},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

.navbar {
  border-top: 1px solid $color-gray;

  height: $navbarHeight;
  width: 100%;

  position: fixed;

  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);

  display: flex;
  justify-content: space-evenly;

  background-color: $color-white;

  z-index: $navbarZindex;

  .link {
    height: 100%;
    width: 25%;

    text-decoration: none;

    &.router-link-active {
      .icon {
        stroke: $color-orange;
        fill: $color-orange;
      }
    }

    .icon {
      margin-top: 0.5rem;
      height: 2rem;
      margin-bottom: 0.25rem;
      width: 100%;

      stroke: $color-black;
      fill: $color-black;
    }

    .name {
      line-height: 1rem;
      font-size: 0.7rem;

      color: $color-black;
    }
  }
}
</style>
