//ホームに表示する注文受け付けステータスのバナー

<template>
  <template v-if="currentStatus == EnumOrderProgressStatus.accepting">
    <div
      class="info-order-status accepting"
      ontouchstart=""
      @click="moveToOrder"
    >
      <div class="row-1">
        <div>注文受付中</div>
      </div>
      <div class="row-2">
        <div>注文はこちら</div>
      </div>
    </div>
  </template>

  <template v-else-if="currentStatus == EnumOrderProgressStatus.closed">
    <div class="info-order-status closed">
      当日注文の受付は終了しました
    </div>
  </template>

  <template v-else-if="currentStatus == EnumOrderProgressStatus.ordered">
    <div class="info-order-status ordered">
      <div class="icon">
        <svg-image name="status_ordered" />
      </div>
      <div class="status">
        注文済
      </div>
    </div>
  </template>

  <template v-else-if="currentStatus == EnumOrderProgressStatus.inDelivery">
    <div class="info-order-status in-delivery">
      <div class="icon">
        <svg-image name="status_in_delivery" />
      </div>
      <div class="status">
        配送中
      </div>
    </div>
  </template>

  <template v-else-if="currentStatus == EnumOrderProgressStatus.delivered">
    <div class="info-order-status delivered">
      <div class="icon">
        <svg-image name="status_delivered" />
      </div>
      <div class="status">
        配送済
      </div>
    </div>
  </template>

  <template v-else-if="currentStatus == EnumOrderProgressStatus.noOrderDay">
    <div class="info-order-status no-order-day">
      当日注文の受付はできません
    </div>
  </template>

  <template v-else>
    <div class="info-order-status loading">
      <loader />
    </div>
  </template>
</template>

<script>
import { computed } from "vue";
import { EnumOrderProgressStatus, getEnumOrderProgressStatus } from "../util/enum";
import SvgImage from "./SvgImage.vue";
import { useRouter } from "vue-router";
import Loader from "./Loader.vue";
export default {
  components: { SvgImage, Loader },
  props: {
    /**
     * 注文ステータス
     */
    orderStatus: {
      type: Number,
      required: true,
    },
    /**
     * 注文受付中FLG
     */
    isOrderAccepting: {
      type: Boolean,
      required: true,
    },
    /**
     * 注文不可FLG
     */
    isNoOrderDay: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    /**
     * 現在の注文進捗ステータス
     */
    const currentStatus = computed(() => {
      return getEnumOrderProgressStatus(
        props.orderStatus,
        props.isOrderAccepting,
        props.isNoOrderDay
      );
    });

    const router = useRouter();

    /**
     * 注文画面へ遷移する
     */
    const moveToOrder = () => {
      router.push({ name: "Order" });
    };

    return {
      EnumOrderProgressStatus,
      currentStatus,
      moveToOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.info-order-status {
  height: 5rem;
  width: 80vw;
  margin: 0.5rem 0;

  color: $color-white;

  &.accepting {
    background-color: $color-white;
    border: 2px solid $color-orange;
    color: $color-orange;

    &:active {
      background-color: $color-orange;
      color: $color-white;
    }

    display: flex;
    flex-flow: column;
    justify-content: space-evenly;

    .row-2 {
      font-size: 1.5rem;
    }
  }

  .icon {
    height: 3rem;
    fill: $color-white;
    stroke: $color-white;
  }

  .status {
    height: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
  }

  &.in-delivery {
    background-color: $color-orange;
  }

  &.delivered {
    background-color: $color-green;
  }

  &.ordered {
    .icon {
      padding: 0.25rem 0;
    }

    background-color: $color-lightblue;
  }

  &.closed,
  &.no-order-day {
    background-color: $color-gray;

    line-height: 5rem;
    font-weight: bold;
  }

  &.loading {
    color: $color-gray;
    border: 2px solid $color-gray;
  }
}
</style>
