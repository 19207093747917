// 0以上の数値を設定するスピナーボタン
// 数値が0のときはそれ以上-を押せなくなる

<template>
  <div class="spinner-button">
    <div
      class="btn btn-decrement"
      :class="{ clickable: isDecrementClickable }"
      @click="decrement"
    >
      <span>－</span>
    </div>
    <div class="value">
      <span>{{ modelValue }}</span>
    </div>
    <div
      class="btn btn-increment"
      :class="{ clickable: isIncrementClickable }"
      @click="increment"
    >
      <span>＋</span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    /**
     * 数の変更が有効かどうかのFLG
     * trueであれば一切変更できない
     */
    isFreezed: {
      type: Boolean,
      default: false,
    },
    /**
     * v-modelで受け取った値、ボタンではこの数値を上下させる
     */
    modelValue: {
      type: Number,
      default: 0,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    /**
     * 数量減少が押せる状態か
     * isFreezedがtrueかvalueが0のときは減少不可
     */
    const isDecrementClickable = computed(() => {
      return props.isFreezed == false && props.modelValue !== 0;
    });

    /**
     * 数量
     */
    const decrement = () => {
      if (isDecrementClickable.value == false) return;
      emit("update:modelValue", Math.max(0, props.modelValue - 1));
    };

    /**
     * 数量増加が押せる状態か
     */
    const isIncrementClickable = computed(() => {
      return props.isFreezed == false;
    });

    const increment = () => {
      if (isIncrementClickable.value == false) return;

      emit("update:modelValue", props.modelValue + 1);
    };

    return {
      isDecrementClickable,
      decrement,
      isIncrementClickable,
      increment,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
.spinner-button {
  display: flex;

  height: 3rem;
  width: min-content;

  .btn {
    font-size: 1.5rem;

    width: 3rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    &.btn-decrement {
      border-left: 1px solid black;

      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &.clickable {
        color: $color-blue;
      }
    }

    &.btn-increment {
      border-right: 1px solid black;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &.clickable {
        color: $color-blue;
      }
    }
  }

  .value {
    width: 3rem;

    border: 1px solid black;

    font-size: 1.5rem;
  }

  div {
    padding-top: 1.5rem;
    span {
      display: block;
      transform: translateY(-50%);
    }
  }
}
</style>
