<template>
  <transition appear>
    <div
      v-if="isShow"
      class="background"
      @click.stop.self="close"
    >
      <div
        v-if="isShow"
        class="modal"
        @click.stop="() => {}"
      >
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

$modalWidth: 90vw;

.background {
  z-index: 10;

  overflow: scroll;

  position: fixed;

  height: 100vh;
  width: 100vw;

  background-color: rgba($color: #000000, $alpha: 0.6);
  top: 0;

  &.v-enter-active,
  &.v-leave-active {
    transition: background-color 0.5s ease;
    .modal {
      transition: all 0.5s ease;
    }
  }

  &.v-enter-from,
  &.v-leave-to {
    background-color: transparent;
    .modal {
      opacity: 0;
      transform: translateY(5vh);
    }
  }

  &.v-enter-to,
  &.v-leave-from {
    background-color: rgba($color: #000000, $alpha: 0.6);
    .modal {
      opacity: 1;
      transform: translateY(0vh);
    }
  }

  vertical-align: middle;

  .modal {
    height: fit-content;
    width: $modalWidth;

    position: absolute;
    top: 0;
    bottom: $navbarHeight;
    left: 0;
    right: 0;

    margin: auto;

    background-color: $color-white;
    box-shadow: 0 0 10px black;

    .modal-body{
      margin:1.5rem;
    }
  }
}
</style>
