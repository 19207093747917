// 認証コード6桁入力用input

<template>
  <input
    type="text"
    maxlength="6"
    :value="modelValue"
    @input="onInput"
  >
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    /**
     * テキスト入力時のイベントハンドラー
     * 変更を親に通知する
     */
    const onInput = (e) => {
      emit("update:modelValue", e.target.value);
    };
    return {
      onInput,
    };
  },
};
</script>

<style lang="scss" scoped>
//等幅フォント
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

input {
  display: block;

  font-size: 4rem;
  font-family: "Roboto Mono", monospace;
  letter-spacing: 0.5rem;

  width: 17.4rem;
  padding: 0;

  border: none;
  border-radius: 0px;

  /* 下破線 */
  background-image: linear-gradient(
    to right,
    orange 0,
    orange 2.4rem,
    transparent 2.4rem,
    transparent 2.9rem
  ); /* 幅2の線を作る */
  background-size: 2.9rem 2px; /* グラデーションの幅・高さを指定 */
  background-position: left bottom; /* 背景の開始位置を指定 */
  background-repeat: repeat-x; /* 横向きにのみ繰り返す */

  &:focus {
    outline: none;
  }
}
</style>
