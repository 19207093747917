<template>
  <div class="order-history-card">
    <div class="header">
      {{ displayDate
      }}<span
        class="status"
        :class="refEnum.getEnumByOrderStatus(order.orderStatus).className"
      >{{
        refEnum.getEnumByOrderStatus(order.orderStatus).text
      }}</span>
    </div>

    <div class="body">
      <table>
        <tbody>
          <tr
            v-for="detail in order.orderDetails"
            :key="detail.productCd"
          >
            <th scope="row">
              {{ detail.productName }}
            </th>
            <td>×</td>
            <td class="cell-quantity">
              {{ detail.quantity.toLocaleString() }}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td
              colspan="3"
              class="cell-amount"
            >
              <span class="bold">{{ order.totalPrice.toLocaleString() }}</span>円
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import * as Enum from "../util/enum";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  setup(props) {

    /**
     * 注文日を表示用の形(yyyy/mm/dd)にフォーマットする
     */
    let displayDate = computed(() => {
      return (
        props.order.orderDate.getFullYear() +
        "/" +
        (props.order.orderDate.getMonth() + 1) +
        "/" +
        props.order.orderDate.getDate()
      );
    });

    return {
      displayDate,
    };
  },
  computed: {
    refEnum: () => Enum,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.order-history-card {
  .header {
    text-align: left;

    span {
      display: inline-block;
      border-radius: 0.25rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: $color-white;
      margin: 0 0.5rem;

      &.undelivered {
        background-color: $color-orange;
      }

      &.delivered {
        background-color: $color-green;
      }

      &.canceled {
        background-color: $color-gray;
      }
    }
  }

  .body {
    table {
      width: 100%;

      th {
        text-align: left;
        font-weight: normal;
      }

      td {
        &.cell-quantity {
          text-align: right;
          padding-right: 0.5rem;

          width: 4rem;
        }

        &.cell-amount {
          //合計
          text-align: right;
          padding-right: 0.5rem;
        }
      }

      span {
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
