export const EnumOrderStatus = {
  UnOrdered: {
    val: 1,
    text: "未注文",
    className: "unordered",
  },
  UnDelivered: {
    val: 2,
    text: "未配達",
    className: "undelivered",
  },
  Delivered: {
    val: 3,
    text: "配達済",
    className: "delivered",
  },
  Canceled: {
    val: 4,
    text: "キャンセル済",
    className: "canceled",
  },
};

export const getEnumByOrderStatus = (orderStatusCd) => {
  switch (orderStatusCd) {
    case EnumOrderStatus.UnOrdered.val:
      return EnumOrderStatus.UnOrdered;

    case EnumOrderStatus.UnDelivered.val:
      return EnumOrderStatus.UnDelivered;

    case EnumOrderStatus.Delivered.val:
      return EnumOrderStatus.Delivered;

    case EnumOrderStatus.Canceled.val:
      return EnumOrderStatus.Canceled;
  }

  return {
    val: 0,
    text: "",
    className: "",
  };
};

export const EnumOrderProgressStatus = {
  loading: -1,
  accepting: 1,
  closed: 2,
  ordered: 3,
  inDelivery: 4,
  delivered: 5,
  noOrderDay: 6,
};

/**
 * 現在の注文受付状況を判定して取得する
 * @param {Number} orderStatusCd 注文ステータス
 * @param {Boolean} isOrderAccepting 注文受付時間内かどうかのFLG
 * @param {Boolean} isNoOrderDay 注文を受け付けている日かどうかのFLG
 * @returns {EnumOrderProgressStatus} パラメータ３つから判定された現在の注文受付状況列挙値
 */
export const getEnumOrderProgressStatus = (orderStatusCd, isOrderAccepting, isNoOrderDay) => {
  if (orderStatusCd == EnumOrderStatus.UnDelivered.val) {
    //注文済未配達
    if (isOrderAccepting) {
      //注文済み(キャンセル可能)
      return EnumOrderProgressStatus.ordered;
    } else {
      //配送中(キャンセル不可)
      return EnumOrderProgressStatus.inDelivery;
    }
  } else if (orderStatusCd == EnumOrderStatus.Delivered.val) {
    //配達済み
    return EnumOrderProgressStatus.delivered;
  } else if (isNoOrderDay) {
    //注文不可
    return EnumOrderProgressStatus.noOrderDay;
  } else if (
    orderStatusCd == EnumOrderStatus.UnOrdered.val ||
    orderStatusCd == EnumOrderStatus.Canceled.val
  ) {
    //未注文orキャンセル済
    if (isOrderAccepting) {
      //受付中
      return EnumOrderProgressStatus.accepting;
    } else {
      //受付終了
      return EnumOrderProgressStatus.closed;
    }
  } else {
    //その他
    return EnumOrderProgressStatus.loading;
  }
};
