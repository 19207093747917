//注文キャンセル確認モーダル

<template>
  <dialog-modal :is-show="isShowModal">
    <div
      v-if="!isCanceled"
      class="cancel-confirm"
    >
      <div class="title">
        注文をキャンセルしてよろしいですか？
      </div>

      <div class="message">
        一度キャンセルすると、取り消しが出来ません。
      </div>

      <pill-button
        class="btn small gray"
        @click="closeModal"
      >
        いいえ
      </pill-button>
      <pill-button
        class="btn small gray"
        @click="doCancel"
      >
        はい
      </pill-button>
    </div>

    <div
      v-if="isCanceled"
      class="canceled"
    >
      <div class="title">
        {{ apiMessage }}
      </div>
      <pill-button
        class="small gray"
        @click="end"
      >
        確認
      </pill-button>
    </div>
  </dialog-modal>
</template>

<script>
import { inject } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import DialogModal from "./DialogModal.vue";
import PillButton from "./PillButton.vue";
import { formatDateToAPIParam } from "../util/helper";
export default {
  components: { DialogModal, PillButton },

  props: {
    divisionCd: {
      type: Number,
      required: true,
    },
  },

  emits: ["order-end"],

  setup(props, { emit }) {
    let store = useStore();
    let isShowModal = ref(false);

    let isCanceled = ref(false);

    const openModal = () => {
      isShowModal.value = true;
    };

    const closeModal = () => {
      isShowModal.value = false;
    };

    const axios = inject("axios");

    const apiMessage = ref("");

    /**
     * キャンセル実行
     * キャンセルAPIにリクエストする
     */
    const doCancel = () => {

      axios
        .post("/order/do_cancel", {
          division_cd: props.divisionCd,
          order_date: formatDateToAPIParam(store.getters.selectedDate),
        })
        .then((response) => {
          console.log(response.data);
          apiMessage.value = response.data.header.message;
        })
        .catch((error) => {
          apiMessage.value = "サーバーとの通信に失敗したため、キャンセルできませんでした。";
        })
        .finally(() => {
          isCanceled.value = true;
        });
    };

    const end = () => {
      isShowModal.value = false;
      emit("order-end");
    };

    return {
      isShowModal,
      openModal,
      closeModal,
      doCancel,
      isCanceled,
      end,
      apiMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.cancel-confirm {
  text-align: left;
  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .message {
    margin: 0.5rem auto;
  }

  .btn {
    margin-top: 0.5rem;
  }
}

.canceled {
  .title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 2.5rem auto;
  }
}
</style>
