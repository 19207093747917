// API不通時に表示するトースト

<template>
  <transition>
    <div
      v-show="isShow"
      class="network-error"
    >
      <span class="message"> インターネットに接続できません。 </span>
      <div
        class="x-btn"
        @click="close"
      >
        <svg-image name="x" />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import SvgImage from "./SvgImage.vue";
export default {
  components: { SvgImage },
  setup(props) {
    const store = useStore();

    const isShow = computed(() => {
      return store.state.isNetworkError;
    });

    const close = () => {
      store.commit("setIsNetworkError", false);
    };

    return {
      isShow,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";
@import "../assets/styles/variables.scss";

.network-error {
  position: fixed;
  bottom: calc(#{$navbarHeight} + 2rem);
  z-index:100;

  height: 3rem;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  border-radius: 0.25rem;

  background-color: $color-gray;

  .message {
    display: inline-block;
    line-height: 3rem;
    width: 100%;
    font-weight: bold;
    color:$color-white;
  }

  .x-btn {
    width: 1.5rem;
    margin: 0 0.5rem;

    position: absolute;
    top:0;
    bottom:0;
    right:0;

    svg{
      stroke:$color-white;
    }
  }

  &.v-enter-active,
  &.v-leave-active{
    transition: all 0.3s ease;
  }

  &.v-enter-from,
  &.v-leave-to{
    transform:translateY(1rem);
    opacity:0;
  }

  &.v-enter-to,
  &.v-leave-from{
    transform:translateY(0);
    opacity:1;
  }


}
</style>
