<template>
  <div class="error">
    <div class="icon">
      <svg-image name="error" />
    </div>

    <div class="message">
      エラーが発生しました。
    </div>
    <div class="message">
      公式アカウントのメニューから
    </div>
    <div class="message">
      アプリを開き直してください。
    </div>
  </div>
</template>

<script>
import SvgImage from '../components/SvgImage.vue'
export default {
  components: { SvgImage },

}
</script>

<style lang="scss" scoped>
@import '../assets/styles/colors.scss';

  .error{
    height:100vh;
    width:100vw;

    .icon{
      padding-top:10vh;
      width:100%;
      height:30vh;

      margin-bottom:10vh;

      stroke:$color-error;
    }

    .message{
      margin-top:0.8rem;

      font-size:1.5rem;
      font-weight: bold;

    }

  }

</style>