//弁当注文タブ用ストア

const state = {

    selectedDate:new Date(),

};
const getters = {
    selectedDate: state => state.selectedDate,
};
const mutations = {

    selectDate(state, date){
        state.selectedDate = date
    },

};
const actions = {

    selectDate({commit},date){
        commit('selectDate',date);
    },

};

export default{
    state,
    getters,
    mutations,
    actions
}