// その他タブの画面

<template>
  <div class="others">
    <router-link :to="{ name: 'Root' }">
      <div class="link-item">
        <span>ご利用方法</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  components: {},
  setup(props) {},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.others {
  padding-top: 3rem;
}

.link-item {
  width: 100%;
  height: 3rem;

  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;

  padding-top: 1.5rem;

  span {
    font-size: 1.2rem;
    display: block;
    transform: translate(0, -50%);
  }
}
</style>
