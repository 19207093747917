// ピルボタン

<template>
  <button @click="onClick">
    <span v-if="!loading">
      <slot />
    </span>
    <span v-if="loading">
      <loader class="white" />
    </span>
  </button>
</template>

<script>
import Loader from "./Loader.vue";
export default {
  components: { Loader },

  props: {
    /**
     * ボタンクリック時に動かす関数
     */
    onClick: {
      type: Function,
      default: () => {},
    },
    /**
     * ローディング中FLG
     * ローディング中はボタン内にローダーを表示する
     */
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

button {
  width: 100%;
  height: 3rem;

  color: white;

  border-radius: 1.5rem;
  border: none;

  font-size: 1.5rem;

  &.orange {
    background-color: $color-orange;
  }

  &.green {
    background-color: $color-green;
  }

  &.gray {
    background-color: $color-gray;
  }

  &.small {
    height: 2.5rem;
    border-radius: 1.25rem;

    font-size: 1rem;
  }
}
</style>
