<template>
  <img
    v-svg-inline
    :src="svgSrc"
    :alt="'svg' + name + 'image'"
  >
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const svgSrc = require(`@/assets/svg/${props.name}.svg`);

    return {
      svgSrc,
    };
  },
};
</script>

<style lang="scss" scoped>
svg {
  height: 100%;
  width: 100%;
  display: block;

  &:focus,
  &:active {
    outline: none;
  }
}
</style>
