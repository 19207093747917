// 注文のカレンダー画面

<template>
  <div>
    <div class="header">
      <div class="year">
        {{ displayYear }}
      </div>
      <div class="month">
        {{ displayMonth }}月
      </div>
    </div>

    <div>
      <div class="calendar-header">
        <div class="calendar-cell cell-sun">
          日
        </div>
        <div class="calendar-cell cell-mon">
          月
        </div>
        <div class="calendar-cell cell-tue">
          火
        </div>
        <div class="calendar-cell cell-wed">
          水
        </div>
        <div class="calendar-cell cell-thu">
          木
        </div>
        <div class="calendar-cell cell-fri">
          金
        </div>
        <div class="calendar-cell cell-sat">
          土
        </div>
      </div>

      <div class="calendar-body">
        <div
          v-for="(week, wIndex) in days"
          :key="wIndex"
          :ref="setWeeksRef"
          class="week-row"
          :class="'week-' + wIndex"
        >
          <calendar-cell
            v-for="(day, dIndex) in week"
            :key="dIndex"
            class="calendar-cell"
            :class="getCellClass(day.date)"
            :date="day.date"
            :order-status="day.orderStatus"
            :is-no-order-day="day.isNoOrderDay"
            :is-order-accepting="day.isOrderAccepting"
            :is-prop-initialized="isInitialized"
            @clicked="onCellClicked"
          />
        </div>
      </div>
    </div>

    <order-modal
      ref="modal"
      :is-freezed="isModalFreezed"
    >
      <order-reception
        @order-start="orderStart"
        @order-end="orderEnd"
      />
    </order-modal>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted, nextTick } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import CalendarCell from "../components/CalendarCell.vue";
import OrderModal from "../components/OrderModal.vue";
import OrderReception from "../components/OrderReception.vue";
import { createDateWithOutTime } from "../util/helper";

const daysPerWeek = 7,
  dowSaturday = 6;

export default {
  components: { CalendarCell, OrderModal, OrderReception },
  setup(props) {
    const store = useStore();
    const days = ref(generateDaysArray()),
      isInitialized = ref(false);

    const axios = inject("axios");

    /**
     * 今日の日付を基準に注文情報を取得
     */
    const getOrdersInfo = () => {
      axios
        .post("/order/get_order_list", {
          today: store.getters.todayForAPIRequest,
        })
        .then((response) => {
          if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
            let orders = response.data.body.orders;

            orders.forEach((order) => {
              let orderDate = createDateWithOutTime(new Date(order.order_date));

              //カレンダー配列[0][0]との差からorderを反映する日を探して設定
              let dateDiff = getDateDiff(days.value[0][0].date, orderDate);
              let weekIndex = Math.floor(dateDiff / daysPerWeek);
              let dayIndex = dateDiff % daysPerWeek;

              if(weekIndex<0 || days.value.length<=weekIndex
              || dayIndex<0  || days.value[weekIndex].length<=dayIndex){
                //indexが範囲外(受け取ったデータがカレンダー配列に存在しない→不要なデータ)はスキップ
                return;
              }

              if (order.order_status) {
                days.value[weekIndex][dayIndex].orderStatus = order.order_status;
              }
              if (order.no_order_flg) {
                days.value[weekIndex][dayIndex].isNoOrderDay = order.no_order_flg == 2;
              }
              if (order.order_limit_flg) {
                days.value[weekIndex][dayIndex].isOrderAccepting = order.order_limit_flg == 1;
              }
            });
            isInitialized.value = true;
          }
        });
    };

    //初回読み込み時に実行
    getOrdersInfo();

    /**
     * セルのクリックのハンドラー
     * 日付をイベントのデータから設定し、注文用モーダルを開く
     */
    const onCellClicked = (cellDate) => {

      //ストアの選択日付を変更
      store.dispatch('selectDate',cellDate);

      modal.value.open();
    };

    /**
     * Dateをもらってその日のセルのクラスを設定する
     */
    const getCellClass = computed(() => {
      return (day) => {
        let result = "";

        switch (day.getDay()) {
          case 0:
            result = "cell-sun";
            break;
          case 1:
            result = "cell-mon";
            break;
          case 2:
            result = "cell-tue";
            break;
          case 3:
            result = "cell-wed";
            break;
          case 4:
            result = "cell-thu";
            break;
          case 5:
            result = "cell-fri";
            break;
          case 6:
            result = "cell-sat";
            break;
        }

        if (
          day.getFullYear() == store.state.today.getFullYear() &&
          day.getMonth() == store.state.today.getMonth() &&
          day.getDate() == store.state.today.getDate()
        ) {
          result += " today";
        }

        return result;
      };
    });

    /**
     * スクロール位置によるヘッダーの月表示制御
     */
    const weeks = ref([]),
      calendarTopY = ref(0),
      displayYear = ref(store.state.today.getFullYear()),
      displayMonth = ref(store.state.today.getMonth() + 1);

    /**
     * マウント完了後に各種のY座標の初期値を保存しておく
     */
    onMounted(() => {
      //スクロール位置を0に設定
      document.documentElement.scrollTop = 0;
      //各週のY座標を保存する
      weeks.value.forEach((week, wIndex) => {
        week.y = week.element.getBoundingClientRect().y;
        if (wIndex == 0) {
          //index:0(１週目)のy座標をカレンダー上辺のY座標として保存
          calendarTopY.value = week.y;
        }
      });
    });

    /**
     * 各週のelementを保存する
     */
    const setWeeksRef = (el) => {
      weeks.value.push({
        element: el,
        y: null,
      });
    };

    /**
     * スクロール時のハンドラー
     */
    const scrollHandler = () => {
      //行が欠けていない最初の週を取得
      let current = document.documentElement.scrollTop;
      let currentTopWeek = weeks.value.find((el) => {
        return calendarTopY.value + current <= el.y;
      });
      let weekIndex = weeks.value.indexOf(currentTopWeek);

      //取得した週の土曜日で年月表示を更新
      displayYear.value = days.value[weekIndex][dowSaturday].date.getFullYear();
      displayMonth.value = days.value[weekIndex][dowSaturday].date.getMonth() + 1;
    };
    //スクロール時のイベントハンドラーを追加
    window.addEventListener("scroll", scrollHandler);
    //画面を離れる際にスクロールのイベントリスナーを消す
    onBeforeRouteLeave((to, from) => {
      window.removeEventListener("scroll", scrollHandler);
    });

    /**
     * モーダルの制御
     */
    const modal = ref(null),
      isModalFreezed = ref(false);

    /**
     * 注文開始
     */
    const orderStart = () => {
      //注文の処理が始まったらモーダルを閉じさせない
      isModalFreezed.value = true;
    };
    /**
     * 注文終了
     * モーダルのフリーズを解除し、閉じる
     * その後注文情報を取り直す(注文実行/キャンセルのデータを取得するため)
     */
    const orderEnd = () => {
      isModalFreezed.value = false;
      nextTick(() => {
        modal.value.close();
      });
      //データを取り直す(カレンダーを更新)
      getOrdersInfo();
    };

    return {
      days,
      isInitialized,
      onCellClicked,
      getCellClass,
      setWeeksRef,
      displayYear,
      displayMonth,
      modal,
      isModalFreezed,
      orderStart,
      orderEnd,
    };
  },
};

/**
 * カレンダー配列を生成する
 */
function generateDaysArray() {
  //何ヶ月分のカレンダーを生成するか
  let displayMonthCount = 2;
  let days = [];

  //今月月初
  let startDate = createDateWithOutTime(new Date());
  startDate.setDate(1);
  //最初の日を日曜日までずらす
  startDate.setDate(startDate.getDate() - startDate.getDay());

  //(monthCount-1)ヶ月後の月末 ← 当月1日の月にmontchCountを足して、1日戻る
  let endDate = createDateWithOutTime(new Date());
  endDate.setDate(1);
  endDate.setMonth(endDate.getMonth() + displayMonthCount);
  endDate.setDate(endDate.getDate() - 1);
  //最終日を土曜日までずらす
  endDate.setDate(endDate.getDate() + (dowSaturday - endDate.getDay()));

  let weekCount = (getDateDiff(startDate, endDate) + 1) / 7;

  let date = new Date(startDate.getTime());
  let week = new Array(daysPerWeek);
  for (let w = 0; w < weekCount; w++) {
    for (let dow = 0; dow <= dowSaturday; dow++) {
      //dateをインクリメントしながら１週間の配列(week)を作成する
      week[dow] = {
        date: new Date(date.getTime()),
        orderStatus: 0,
        isNoOrderDay: true,
        isOrderAccepting: false,
      };
      date.setDate(date.getDate() + 1);
    }
    //days配列に週を追加
    days.push(week.concat());
  }

  return days;
}

/**
 * dateFromとdateToの日付差を取得する
 */
function getDateDiff(dateFrom, dateTo) {
  const milliSecondsPerDay = 86400000;

  let timeDiff = dateTo.getTime() - dateFrom.getTime();
  return timeDiff / milliSecondsPerDay;
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

$theadHeight: 3.5vh;
$cellHeight: calc((#{$pageViewHeight} - #{$theadHeight}) / 6);

.header {
  position: fixed;
  top: 0;
  height: $pageHeaderHeight;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $color-white;
  z-index: 1;

  .year {
    font-size: 0.7rem;
  }

  .month {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.calendar-header {
  display: block;
  position: fixed;
  top: $pageHeaderHeight;
  height: $theadHeight;

  background-color: $color-white;
  z-index: 1;

  display: flex;

  .calendar-cell {
    width: 14vw;

    &.cell-sun {
      width:15vw;
      color: $color-red;
    }

    &.cell-sat {
      width:15vw;
      color: $color-blue;
    }
  }
}

.calendar-body {
  display: block;
  margin-top: calc(#{$pageHeaderHeight} + #{$theadHeight});
  padding-bottom: $navbarHeight;

  .week-row {
    display: flex;

    .calendar-cell {
      height: $cellHeight;
      width: 14vw;
      padding: 0;
      border: none;

      &.today {
        border: 1px solid black;
      }

      &.cell-sun {
        width:15vw;
        color: $color-red;
      }

      &.cell-sat {
        width:15vw;
        color: $color-blue;
      }
    }
  }
}
</style>
