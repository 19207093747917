// カレンダーのセル用コンポーネント

<template>
  <div
    class="cell"
    :class="monthParityClassName"
    @click="onClick"
  >
    <div class="date">
      {{ date.getDate() }}
    </div>

    <div class="icon">
      <transition appear>
        <div
          v-if="
            isPropInitialized &&
              (progressStatus == EnumOrderProgressStatus.accepting ||
                progressStatus == EnumOrderProgressStatus.loading)
          "
          class="icon-inner"
        />
      </transition>
      <transition appear>
        <div
          v-if="
            isPropInitialized &&
              (progressStatus == EnumOrderProgressStatus.ordered ||
                progressStatus == EnumOrderProgressStatus.inDelivery)
          "
          class="icon-inner"
        >
          <svg-image
            class="icon-image ordered"
            name="circle"
          />
        </div>
      </transition>
      <transition appear>
        <div
          v-if="isPropInitialized && progressStatus == EnumOrderProgressStatus.delivered"
          class="icon-inner"
        >
          <svg-image
            class="icon-image delivered"
            name="circle"
          />
        </div>
      </transition>
      <transition appear>
        <div
          v-if="
            isPropInitialized &&
              (progressStatus == EnumOrderProgressStatus.closed ||
                progressStatus == EnumOrderProgressStatus.noOrderDay)
          "
          class="icon-inner"
        >
          <svg-image
            class="icon-image no-order-day"
            name="x"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import SvgImage from "./SvgImage.vue";
import { EnumOrderProgressStatus, getEnumOrderProgressStatus } from "../util/enum.js";

export default {
  components: { SvgImage },
  props: {

    /**
     * セルに表示する日付
     */
    date: {
      type: Date,
      required: true,
    },

    /**
     * セルの表示している日の注文状態
     */
    orderStatus: {
      type: Number,
      required: true,
    },

    /**
     * 注文不可日FLG
     */
    isNoOrderDay: {
      type: Boolean,
      required: true,
    },

    /**
     * 注文受付中FLG
     */
    isOrderAccepting: {
      type: Boolean,
      required: true,
    },

    /**
     * 各Propの初期化完了FLG
     */
    isPropInitialized: {
      type: Boolean,
    },
  },

  emits: ["clicked"],

  setup(props, { emit }) {

    /**
     * 表示中セルの月の偶奇を表すクラス名
     */
    const monthParityClassName = computed(() => {
      return (props.date.getMonth() + new Date().getMonth()) % 2 == 1 ? "odd" : "even";
    });

    /**
     * クリック時のイベントハンドラー
     * 注文可能な状態であれば、日付をつけてイベントを起こす
     */
    const onClick = () => {
      if (
        progressStatus.value == EnumOrderProgressStatus.closed ||
        progressStatus.value == EnumOrderProgressStatus.noOrderDay
      ) {
        //注文不可日はクリックイベントを起こさない
        return;
      }

      emit("clicked", props.date);
    };

    /**
     * 各Propsから注文の進捗状態を決定する
     */
    const progressStatus = computed(() => {
      return getEnumOrderProgressStatus(
        props.orderStatus,
        props.isOrderAccepting,
        props.isNoOrderDay
      );
    });

    return {
      monthParityClassName,
      onClick,
      EnumOrderProgressStatus,
      progressStatus,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.cell {
  width: 101%;
  height: 101%;
  //ios系で切り捨てられた際にいっぱいに表示されるように100+1%

  &.odd {
    background-color: rgb(231, 231, 231);
  }

  position: relative;

  .date {
    position: absolute;
    top: 0;

    text-align: left;
    padding-left: 0.3rem;
    font-size: 0.8rem;
  }

  .icon {
    height: 100%;
    width: 8vw;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .icon-inner {
      width: 100%;
    }

    .icon-image {
      &.no-order-day {
        stroke: darken($color: $color-gray, $amount: 10);
        stroke: darken($color: $color-gray, $amount: 10);
      }

      &.ordered {
        stroke: $color-orange;
        fill: $color-orange;
      }

      &.delivered {
        stroke: $color-green;
        fill: $color-green;
      }
    }

    .v-enter-active {
      transition: all 0.3s ease-in;
    }
    .v-enter-from {
      transform: scale(0);
    }
    .v-enter-to {
      transform: scale(1);
    }
  }
}
</style>
