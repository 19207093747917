import { createRouter, createWebHistory } from "vue-router";
import Root from "../views/Root.vue";
import Main from "../views/Main.vue";
import store from "../store/index.js";

/**
 * meta:{
 *  requireAuth:Boolean →各ルートの表示に認証が必要な場合はtrue
 *  title:String →各ルートの表示時に変更するタイトル
 * }
 */

const routes = [
  {
    path: "/",
    name: "Root",
    component: Root,
    meta:{
      requireAuth:false,
      title:"読込中..."
    },
  },
  {
    path: "/preAuth",
    name: "PreAuth",
    component: () => import("../views/PreAuth.vue"),
    meta:{
      requireAuth:false,
      title:"アカウント連携"
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Auth.vue"),
    props: true,
    meta:{
      requireAuth:false,
      title:"アカウント連携"
    },
  },
  {
    path: "/app",
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/Home.vue"),
        meta:{
          requireAuth:true,
          title:"ホーム"
        },
      },
      {
        path: "order",
        name: "Order",
        component: () => import("../views/Order.vue"),
        meta:{
          requireAuth:true,
          title:"弁当注文"
        },
      },
      {
        path: "history",
        name: "History",
        component: () => import("../views/History.vue"),
        meta:{
          requireAuth:true,
          title:"注文履歴"
        },
      },
      {
        path: "others",
        name: "Others",
        component: () => import("../views/Others.vue"),
        meta:{
          requireAuth:true,
          title:"その他"
        },
      },
    ],
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
    props: true,
    meta:{
      requireAuth:false,
      title:"エラー"
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    //認証が必要なページにアクセスした場合はログイン状態をチェックする
    if (store.getters.isLoggedIn) {
      next();
    } else {
      next("/");
    }
  }else{
    if(store.getters.isLoggedIn){
    //認証ページにログイン済みでアクセスした場合はホーム画面へ遷移
      next("/home");
    }else{
      next();
    }
  }
});

router.afterEach((to, from) => {
  if(to.meta.title){
    //遷移先のタイトルに変更する
    document.title = to.meta.title;
  }
});

export default router;
