// 注文ダイアログの中身

<template>
  <div class="order-reception">
    <div class="order-date">
      {{ displayDate }}
    </div>
    <div class="order-rows">
      <template
        v-for="(product, index) in details"
        :key="product.product_name"
      >
        <div class="divider" />
        <order-row
          v-model:quantity="product.quantity"
          :is-freezed="
            isWaitingPayment ||
              isOrderCompleted ||
              orderProgressStatus != EnumOrderProgressStatus.accepting
          "
          :product-name="product.productName"
          :unit-price="product.unitPrice"
        />
        <div
          v-if="index == details.length - 1"
          class="divider"
        />
      </template>
    </div>

    <div class="reception-footer">
      <div class="order-amount">
        合計 <span class="amount">{{ displayAmount }}</span> 円
        <div class="tax">
          （内消費税 <span class="tax-amount">{{ displayTaxAmount }}</span> 円）
        </div>
      </div>

      <div class="operation-panel">
        <template v-if="isApiError">
          {{ apiErrorMessage }}
        </template>

        <template v-else-if="orderProgressStatus == EnumOrderProgressStatus.noOrderDay">
          この日は注文を受け付けていません
        </template>

        <template v-else-if="orderProgressStatus == EnumOrderProgressStatus.closed">
          注文受付を終了しました
        </template>

        <template v-else-if="orderProgressStatus == EnumOrderProgressStatus.ordered">
          <div class="btn">
            <pill-button
              class="gray small"
              @click="orderCancelModal.openModal()"
            >
              注文をキャンセル
            </pill-button>
          </div>
        </template>

        <template v-else-if="orderProgressStatus == EnumOrderProgressStatus.accepting">
          <div
            v-if="isWaitingPayment == false && isOrderCompleted == false"
            class="btn"
          >
            <pill-button
              class="small"
              :class="{ gray: !canOrder, green: canOrder }"
              @click="doOrder"
            >
              LINEPayで決済
            </pill-button>
          </div>

          <template 
            v-if="isWaitingPayment == true"
          >
            <!-- 決済待ち -->
            <div class="wait-payment">
              <div class="col-loader">
                <loader class="loader green" />
                決済の完了を待っています…
              </div>

              <div class="col-button">
                <div class="payment-button">
                  <pill-button
                    class="small gray"
                    @click="openPayment"
                  >
                    決済画面を開く
                  </pill-button>
                </div>
              </div>
            </div>
          </template>

          <template v-if="isOrderCompleted == true">
            <div>
              <svg-image
                class="icon-complete"
                name="payment_complete"
              />
              <div class="message">
                決済完了
              </div>
            </div>
          </template>
        </template>

        <div v-else-if="orderProgressStatus == EnumOrderProgressStatus.inDelivery">
          <div>受付時間を過ぎているため、</div>
          <div>注文のキャンセルは出来ません。</div>
        </div>

        <template v-else-if="orderProgressStatus == EnumOrderProgressStatus.delivered">
          <div>
            <svg-image
              class="icon-complete"
              name="status_delivered"
            />
            配送済
          </div>
        </template>

        <template v-else>
          <loader />
        </template>
      </div>
    </div>

    <payment-error-modal
      ref="paymentErrorModal"
      :is-payment-canceled="paymentCanceled"
      @order-end="onOrderEnd"
    />
    <order-cancel-modal
      ref="orderCancelModal"
      :division-cd="divisionCd"
      @order-end="onOrderEnd"
    />
  </div>
</template>

<script>
import liff from "@line/liff";
import { inject, computed, ref, nextTick, onBeforeUnmount } from "vue";
import Loader from "./Loader.vue";
import SvgImage from "./SvgImage.vue";
import PillButton from "./PillButton.vue";
import OrderRow from "./OrderRow.vue";
import { useStore } from "vuex";
import PaymentErrorModal from "./PaymentErrorModal.vue";
import OrderCancelModal from "./OrderCancelModal.vue";
import { formatDateToAPIParam } from "../util/helper";
import { EnumOrderProgressStatus, getEnumOrderProgressStatus } from "../util/enum";

export default {
  components: { OrderRow, PillButton, PaymentErrorModal, OrderCancelModal, Loader, SvgImage },

  emits: ["order-start", "order-end"],

  setup(props, { emit }) {
    const store = useStore();
    const details = ref([]),
      orderStatus = ref(-1),
      isOrderAccepting = ref(false),
      noOrderFlg = ref(false),
      roundType = ref(0),
      taxRate = ref(0),
      divisionCd = ref(0);

    const orderProgressStatus = computed(() => {
      return getEnumOrderProgressStatus(
        orderStatus.value,

        isOrderAccepting.value,
        noOrderFlg.value
      );
    });

    const axios = inject("axios");
    const isApiError = ref(false);
    const apiErrorMessage = ref("");

    /**
     * 注文日の注文状態を取得する
     */
    const getOrderInfo = () => {
      axios
        .post("/order/get_order_one", {
          target_date: formatDateToAPIParam(store.getters.selectedDate),
        })
        .then((response) => {
          if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
            divisionCd.value = response.data.body.division_cd; //部署CD
            orderStatus.value = response.data.body.order_status;
            isOrderAccepting.value = response.data.body.order_limit_flg == 1;
            noOrderFlg.value = response.data.body.no_order_flg == 2;
            details.value = response.data.body.order_detail.map((detail) => {
              return {
                productCd: detail.product_cd,
                productName: detail.product_name,
                quantity: detail.quantity,
                unitPrice: detail.unit_price,
              };
            });
            roundType.value = response.data.body.round_type;
            taxRate.value = response.data.body.tax_rate;
          } else {
            //APIからのエラーメッセージを表示
            isApiError.value = true;
            apiErrorMessage.value = response.data.header.message;
          }
        })
        .catch((error) => {
          //APIエラー
          isApiError.value = true;
          apiErrorMessage.value = "サーバーとの通信に失敗しました。";
        });
    };

    //読み込み時に注文情報を取得
    getOrderInfo();

    /**
     * 表示用に注文日をフォーマット(m月d日(曜日))
     */
    const displayDate = computed(() => {
      return (
        store.getters.selectedDate.getMonth() +
        1 +
        "月" +
        store.getters.selectedDate.getDate() +
        "日（" +
        store.getters.selectedDate.toLocaleDateString("ja", { weekday: "short" }) +
        "）"
      );
    });

    /**
     * 合計金額
     */
    const amount = computed(() => {
      return details.value.reduce((sum, detail) => {
        return sum + detail.unitPrice * detail.quantity;
      }, 0);
    });

    /**
     * 合計金額を表示用に3桁区切りにしたもの
     */
    const displayAmount = computed(() => {
      return amount.value.toLocaleString();
    });

    /**
     * 税額
     */
    const taxAmount = computed(() => {
      //税込合計金額から逆算
      return roundByRoundType(
        (amount.value * taxRate.value) / (1 + taxRate.value),
        roundType.value
      );
    });

    /**
     * 税額を表示用に3桁区切りにしたもの
     */
    const displayTaxAmount = computed(() => {
      return taxAmount.value.toLocaleString();
    });

    //注文ボタンが押せる状態か
    const canOrder = computed(() => {
      //金額が0じゃなければ注文可能
      return amount.value > 0;
    });

    let paymentErrorModal = ref(null);
    let paymentCanceled = ref(false);
    let isWaitingPayment = ref(false);
    let isOrderCompleted = ref(false);

    let paymentUrl = ref("");

    /**
     * 注文実行
     * 注文実行APIに接続
     */
    const doOrder = () => {
      if (canOrder.value == false) return;

      emit("order-start");
      isWaitingPayment.value = true;

      //注文APIにアクセス
      axios
        .post("/order/do_pre_order", {
          division_cd: divisionCd.value, //部署CD
          order_date: formatDateToAPIParam(store.getters.selectedDate),
          order_detail: details.value
            .filter((detail) => {
              //数量0の商品は除外
              return detail.quantity != 0;
            })
            .map((detail) => {
              return {
                product_cd: detail.productCd,
                product_name: detail.productName,
                quantity: detail.quantity,
                unit_price: detail.unitPrice,
                amount: detail.unitPrice * detail.quantity,
              };
            }),
          total_tax: taxAmount.value,
          total_price: amount.value,
          round_type: roundType.value,
          tax_rate: taxRate.value,
        })
        .then((response) => {
          console.log(response.data);

          if (response.data.header.result == 1) {
            //成功
            let orderNo = response.data.body.order_no;
            let line_transactionId = response.data.body.line_res.info.transactionId;

            //paymentUrlは、本番か開発かで使い分ける
            //app(LINEアプリ内でLINEPayを開く) : 実際に売上を回収する。api側の「site_settings.php」41行目(LINE PAY の本番API)を有効にしておく必要がある
            //web : sandbox環境。api側の「site_settings.php」41行目(LINE PAY の本番API)はコメントアウトしておく
            paymentUrl.value = response.data.body.line_res.info.paymentUrl.app;
            //paymentUrl.value = response.data.body.line_res.info.paymentUrl.web;

            //決済画面への遷移URLを渡して、ユーザーの支払いを待機する
            waitPayment(paymentUrl.value, orderNo, line_transactionId);
          } else {
            //失敗→決済エラーモーダルを開く
            isWaitingPayment.value = false;
            paymentErrorModal.value.openModal();
          }
        })
        .catch((response) => {
          //エラー発生→決済エラーモーダルを開く
          isWaitingPayment.value = false;
          paymentErrorModal.value.openModal();
        });
    };

    //注文完了後
    const waitPayment = (paymentUrl, orderNo, transactionId) => {
      //決済画面を開く
      liff.openWindow({
        url: paymentUrl,
        external: false,
      });

      checkPaymentStatus(orderNo, transactionId);
    };

    let checkPaymentTimer = ref(-1)
    //1s待機して、決済の状態を確認する。未オーソリの場合は再帰的に呼び出す、完了orキャンセルorエラーで呼び出さず終了
    const checkPaymentStatus = (orderNo, transactionId) => {
      if(checkPaymentTimer.value!=-1){
        //(通常あり得ないが)タイマーが動いていればキャンセルする
        clearTimeout(checkPaymentTimer.value)
        checkPaymentTimer.value = -1
      }
      if(isWaitingPayment.value == false)return;

      checkPaymentTimer.value = setTimeout(() => {
        axios
          .post("/order/is_order_status", {
            order_no: orderNo,
            line_transactionId: transactionId,
          })
          .then((response) => {
            if(response.data.header.result == 1 && response.data.body.response_type == 10) {
              //未オーソリ→再度決済状態を確認する
              checkPaymentStatus(orderNo, transactionId);
            }
            else if (response.data.header.result == 1 && response.data.body.response_type == 30) {
                //注文・決済完了
                isWaitingPayment.value = false;
                isOrderCompleted.value = true;

                nextTick(() => {
                  //決済完了の表示をして、５秒後に完了とする(モーダルを閉じる)
                  setTimeout(() => {
                    emit("order-end");
                  }, 5000);
                });
            } 
            else {
              //未オーソリ以外（エラー/キャンセルなど）
              //LINEPayの返却コード:0121が含まれる場合はユーザーによる支払いキャンセル
              const isCanceled = response.data.body.response_message.includes("0121")

              isWaitingPayment.value = false;
              paymentErrorModal.value.openModal(isCanceled);
            }
          })
          .catch((response) => {
            //エラー発生→決済エラーモーダルを開く?
            isWaitingPayment.value = false;
            paymentErrorModal.value.openModal();
          });
      }, 1000);
    };

    //コンポーネントが消えるときにはタイマーと決済待ち状態を解除する
    onBeforeUnmount(()=>{
      if(checkPaymentTimer.value !=-1){
        isWaitingPayment.value = false
        clearTimeout(checkPaymentTimer.value)
      }
    })

    let orderCancelModal = ref(null);
    const onOrderEnd = () => {
      orderCancelModal.value.closeModal();

      emit("order-end");
    };

    const openPayment = ()=>{
      liff.openWindow({
        url:paymentUrl.value
      })
    }

    return {
      divisionCd,
      displayDate,
      details,
      displayAmount,
      displayTaxAmount,
      paymentErrorModal,
      paymentCanceled,
      orderCancelModal,
      canOrder,
      doOrder,
      isWaitingPayment,
      isOrderCompleted,
      onOrderEnd,
      EnumOrderProgressStatus,
      orderProgressStatus,
      isApiError,
      apiErrorMessage,
      paymentUrl,
      openPayment,
    };
  },
};

/**
 * 丸め区分によって丸めを行う
 */
function roundByRoundType(roundTarget, roundType) {
  if (roundType == 1) {
    //切り上げ
    return Math.ceil(roundTarget);
  } else if (roundType == 2) {
    //切り捨て
    return Math.floor(roundTarget);
  } else {
    //四捨五入
    return Math.round(roundTarget);
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

$orderDateHeight: 3rem;
$orderDateBottomMargin: 1rem;
$orderFooterHeight: 8rem;

.order-reception {
  height: 100%;

  .order-date {
    height: $orderDateHeight;

    font-size: 1.2rem;

    padding-top: 1rem;
    margin-bottom: $orderDateBottomMargin;
    margin-left: 25%;
    margin-right: 25%;
    border-bottom: 1px solid black;
  }

  .order-rows {
    width: fit-content;
    min-width: 100vw;

    height: calc(100% - (#{$orderDateHeight} + #{$orderDateBottomMargin}) - #{$orderFooterHeight});

    overflow-y: scroll;

    .divider {
      border-bottom: 1px solid lightgrey;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .reception-footer {
    height: $orderFooterHeight;

    .order-amount {
      width: fit-content;
      height: 4rem;

      margin-left: auto;
      margin-right: 0.5rem;

      padding-top: 0.25rem;

      font-weight: bold;

      .amount {
        display: inline-block;
        min-width: 30vw;

        text-align: right;
        font-size: 1.5rem;
      }

      .tax {
        border-top: 2px solid $color-black;
        font-weight: normal;
        font-size: 0.8rem;
        text-align: right;

        .tax-amount {
          display: inline-block;
          min-width: 3rem;
          text-align: right;
          font-weight: bold;
        }
      }
    }

    .operation-panel {
      height: 4rem;

      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
      }

      .loader {
        height: 4rem;
      }

      .wait-payment{
        width:100%;
        height:4rem;

        display:flex;

        .col-loader{
          width:60%;
          .loader{
            height:2.5rem;
          }
        }

        .col-button{
          padding-left:0.25rem;
          padding-right:0.25rem;
          width:40%;
          position:relative;

          .payment-button{
            position:absolute;
            //縦に中央に揃える
            top:50%;
            transform: translateY(-50%);
          }
        }
      }

      .icon-complete {
        fill: $color-green;
        stroke: $color-green;
        height: 2.5rem;
      }
      .message {
        color: $color-green;
      }
    }
  }
}
</style>
