<template>
  <transition appear>
    <div
      v-if="isShow"
      class="background"
      @click.stop.self="close"
    >
      <div
        v-if="isShow"
        class="modal"
        @click.stop="() => {}"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";
export default {
  props: {
    isFreezed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isShow = ref(false);

    /**
     * モーダル表示 ※親コンポーネントから呼び出す
     */
    const open = () => {
      isShow.value = true;

      //スクロールを不可とする(背景のスクロールを防ぐ)
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    };

    /**
     * モーダル閉じる ※親コンポーネントから呼び出す
     */
    const close = () => {
      if(props.isFreezed){
        //isFreezedがtrueなら閉じない
        return;
      }

      isShow.value = false;

      //閉じたらスクロールを可能に
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    };
    onBeforeRouteLeave((to, from) => {
      //ページを離れる場合はスクロールを可能に戻してから離れる
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    });

    return {
      isShow,
      open,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

$modalHeight: 70vh;

.background {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.1);
  top: 0;

  z-index: 1;

  &.v-enter-active,
  &.v-leave-active {
    transition: background-color 0.5s ease;
    .modal {
      transition: transform 0.5s ease;
    }
  }

  &.v-enter-from,
  &.v-leave-to {
    background-color: transparent;
    .modal {
      transform: translateY(100vh);
    }
  }

  &.v-enter-to,
  &.v-leave-from {
    background-color: rgba($color: #000000, $alpha: 0.1);
    .modal {
      transform: translateY(0vh);
    }
  }

  .modal {
    position: absolute;
    top: calc(100vh - #{$modalHeight});

    box-shadow: 0 -10px 10px rgba($color: $color-black, $alpha: 0.1);

    width: 100vw;
    height: $modalHeight;
    padding-bottom: $navbarHeight;

    background-color: $color-white;
  }
}
</style>
