/**
 * DateオブジェクトをAPIリクエスト用パラメータの形('yyyy-mm-dd')にフォーマットする
 * @param {Date} date 
 * @returns {String} dateを'yyyy-mm-dd'の形にフォーマットした文字列
 */
export const formatDateToAPIParam = (date)=>{
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}

/**
 * Dateオブジェクトから時間の情報を捨てて(00:00:00に変更して)返却する
 * @param {Date} date 
 * @returns 
 */
export const createDateWithOutTime = (date)=>{

  return new Date(date.getFullYear(),date.getMonth(),date.getDate());

}