// ログイン後の画面レイアウト

<template>
  <div>
    <router-view
      v-slot="{ Component }"
      class="page-component"
    >
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
    <navbar />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  components: { Navbar },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";
.page-component {
  height: 100vh;
  padding-bottom: $navbarHeight;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
  transition-timing-function: ease;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
