import { createStore } from "vuex";
import order from "./modules/order";

export default createStore({
  state: {
    companyCd: "",  //企業CD
    accessToken: "",  //アクセストークン
    today: new Date(),  //今日の日付
    isNetworkError: false,  //ネットワークエラー発生FLG
  },
  getters: {
    //ログイン中かを判定(アクセストークンを取得しているかどうか)
    isLoggedIn: function (state) {
      return state.accessToken != "";
    },
    //今日の日付をAPIリクエスト用に整形して返却
    todayForAPIRequest: function (state) {
      return (
        state.today.getFullYear() + "-" + (state.today.getMonth() + 1) + "-" + state.today.getDate()
      );
    },
  },
  mutations: {
    setCompanyCd: function (state, newCompanyCd) {
      state.companyCd = newCompanyCd;
    },

    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },

    setToday(state, today) {
      state.today = today;
    },

    setIsNetworkError(state, flg) {
      state.isNetworkError = flg;
    },

  },
  actions: {},

  //各画面のmoduleをimport
  modules:{
    order,
  },
});
