// ホームに表示する当日の注文明細

<template>
  <div class="info-order-details">
    <div class="header">
      本日の注文
    </div>
    <div class="detail">
      <div
        v-if="props.orderDetails.length == 0"
        class="none"
      >
        <svg-image name="minus" />
      </div>

      <table v-else>
        <tr
          v-for="detail in orderDetails"
          :key="detail.productName"
        >
          <td class="cell-product-name">
            {{ detail.productName }}
          </td>
          <td class="cell-sign">
            ×
          </td>
          <td class="cell-quantity">
            {{ detail.quantity }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import SvgImage from "./SvgImage.vue";
export default {
  components: {
    SvgImage,
  },
  props: {
    /**
     * 注文明細の配列
     */
    orderDetails: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.info-order-details {
  height: fit-content;
  min-height: 20vh;

  width: 80vw;
  margin: 0.5rem 0;

  border: 2px solid $color-gray;

  .header {
    height:1.5rem;
    margin: 0.5rem auto;
  }

  .none{
    color:$color-gray;
    height:calc(20vh - (1.5rem + 0.5rem * 2));
  }

  table {
    margin: 0 auto;
    width: 90%;

    .cell-product-name {
      text-align: left;
      font-weight: bold;
    }

    .cell-sign {
      width: 1rem;
    }

    .cell-quantity {
      width: 2rem;
      font-weight: bold;
    }
  }
}
</style>
