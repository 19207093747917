import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSvgInlinePlugin from "vue-svg-inline-plugin";

import axiosPlugin from "./plugins/axiosPlugin";

import 'modern-css-reset';

// initialize Vue app
const app = createApp(App);

app.use(axiosPlugin, { store: store, router: router });

// use Vue plugin with options
app.use(VueSvgInlinePlugin, {
  attributes: {
    data: ["src"],
    remove: ["alt"],
  },
});

app.use(store).use(router).mount("#app");
