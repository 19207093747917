<template>
  <router-view
    v-slot="{ Component }"
    class="page"
  >
    <transition>
      <component :is="Component" />
    </transition>
  </router-view>
  <network-error-notification />
</template>

<script>
import NetworkErrorNotification from "./components/NetworkErrorNotification.vue";
import liff from "@line/liff";
import LIFFInspectorPlugin from "@line/liff-inspector";
import { LiffMockPlugin } from "@line/liff-mock";

export default {
  components: { NetworkErrorNotification },

  setup(props) {
    //LIFF inspectorを設定
    liff.use(new LIFFInspectorPlugin());

    let developmentFlg = process.env.NODE_ENV === "development";
    if (developmentFlg) {
      liff.use(new LiffMockPlugin());
    }

    //liffの初期化
    liff
      .init({
        liffId: process.env.VUE_APP_LIFF_ID,
        //TODO  開発用
        //withLoginOnExternalBrowser: developmentFlg,
        mock: developmentFlg,
      })
      .then(() => {
        if(developmentFlg){
          liff.ready = Promise.resolve();
        }
      });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.page.v-enter-active,
.page.v-leave-active {
  transition: opacity 0.3s ease;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.page.v-enter-from,
.page.v-leave-to {
  opacity: 0;
}

.page.v-enter-to,
.page.v-leave-from {
  opacity: 1;
}
</style>
