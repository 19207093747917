// 認証コードの入力画面

<template>
  <div class="auth">
    <div class="logo">
      <svg-image
        class="logo-image"
        name="logo"
      />
    </div>

    <div class="message">
      <div>6桁の認証コードを</div>
      <div>入力してください</div>
    </div>

    <auth-code-input
      v-model="authCode"
      class="input"
    />

    <div class="submit">
      <pill-button
        class="orange"
        :on-click="submitAuthCode"
        :loading="isLoading"
      >
        確定
      </pill-button>
    </div>

    <transition>
      <div
        v-if="isError"
        class="error-message"
      >
        {{ errorMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
import { inject } from "vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PillButton from "../components/PillButton.vue";
import AuthCodeInput from "../components/AuthCodeInput.vue";
import SvgImage from "../components/SvgImage.vue";

export default {
  components: { PillButton, AuthCodeInput, SvgImage },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let authCode = ref(""),
      isLoading = ref(false),
      isError = ref(false),
      errorMessage = ref("");

    const axios = inject("axios");

    /**
     * 認証コードを送信する
     */
    const submitAuthCode = () => {
      //コードの長さチェック
      if (authCode.value.length != 6) {
        displayError("認証コードを入力してください。");
        return;
      }

      //ローダー表示
      isLoading.value = true;

      axios
        .post("/auth/do_pre_auth", {
          company_cd: store.state.companyCd,
          auth_code: authCode.value,
        })
        .then((response) => {
          if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
            //成功

            //ユーザーCDとユーザー名を持って確認画面へ
            router.push({
              name: "Auth",
              params: {
                userCd: response.data.body.user_cd,
                userName: response.data.body.user_name,
              },
            });
          } else {
            displayError("認証コードを確認してください。");
            isLoading.value = false;
          }
        });
    };

    /**
     * エラーメッセージを表示
     */
    const displayError = (message) => {
      isError.value = true;
      errorMessage.value = message;

      //3s後に表示終了
      setTimeout(() => {
        isError.value = false;
      }, 3000);
    };

    return {
      authCode,
      submitAuthCode,
      isLoading,
      isError,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.auth {
  min-height: 100vh;
}

.logo {
  padding-top: 10vh;
  padding-bottom: 10vh;

  .logo-image {
    height: 7rem;
    width: 7rem;
    margin: auto;
  }
}

.message {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.input {
  margin-right: auto;
  margin-left: auto;

  margin-bottom: 2.5rem;
}

.submit {
  width: 15rem;
  margin: auto;
}

.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-leave-from {
  opacity: 1;
}

.v-leave-to {
  opacity: 0;
}

.error-message {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;

  padding: 0.5rem;

  font-size: 1.2rem;
  font-weight: bold;

  background-color: $color-error;
  color: $color-white;

  border-radius: 0.5rem;
}
</style>
