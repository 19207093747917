//初期画面

<template>
  <div class="auth">
    <loader />
  </div>
</template>

<script>
import liff from "@line/liff";
import { inject } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import Loader from "../components/Loader.vue";
import { LiffMockPlugin } from '@line/liff-mock';

export default {
  components: { Loader },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const axios = inject("axios");

    let idToken = ref(""),
      companyCd = ref("");

    /**
     * 認証APIへリクエストし、結果に応じた画面に遷移する
     */
    const auth = () => {
      //会社情報&トークンが揃っていれば処理
      if (companyCd.value && idToken.value) {
        axios
          .post("/auth/is_auth", {
            company_cd: companyCd.value,
            line_auth_id: idToken.value,
          })
          .then((response) => {
            if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
              //認証済みのユーザー→ホームへ
              store.commit("setAccessToken", response.data.body.access_token);
              router.push({ name: "Home" });
            } else {
              //未認証のユーザー→コード入力へ
              router.push({ name: "PreAuth" });
            }
          });
      }
    };

    liff
      .ready
      .then(() => {
        idToken.value = liff.getIDToken();

        if (route.query.c) {
          //クエリパラメータから会社CDを取得
          companyCd.value = route.query.c;
          store.commit("setCompanyCd", companyCd.value);
          auth();
        } else {
          if (store.state.companyCd != "") {
            companyCd.value = store.state.companyCd;
            auth();
          } else {
            //会社情報不明のため処理できない→エラー画面へ遷移

            if (!route.query["liff.state"]) {
              router.push({ name: "Error" });
              return;
            }
          }
        }
      });

    return {
      idToken,
      companyCd,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth {
  height: 100vh;
  width: 50vw;
  margin: auto;
}
</style>
