import axios from "axios";
import JSONbig from "json-bigint";
import bigNumber from "bignumber.js";

export default {
  install: (app, option) => {
    const axiosPlugin = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "json",
      // timeout: 3000,
    });

    //桁の大きな数字に対応する
    axiosPlugin.defaults.transformResponse = [function (data) {
      console.log(data);

      function castBigNumberRecursive(obj)
      {
          for (var k in obj)
          {
            if(typeof obj[k] == "object" && obj[k]!==null){
              if(bigNumber.isBigNumber(obj[k])){
                if(obj[k].comparedTo(Number.MAX_SAFE_INTEGER)==-1
                && obj[k].comparedTo(Number.MIN_SAFE_INTEGER)==1){
                  //通常はNumberとして扱う
                  obj[k] = obj[k].toNumber();
                }else{
                  //大きすぎる値のみ文字列にする(LINEの取引IDなどの値が該当)
                  obj[k] = obj[k].toString();
                }
              }else{
                //BigNumber以外のオブジェクトなら再帰的にキャスト
                castBigNumberRecursive(obj[k]);
              }
            }
          }
      }

      var retObj = JSONbig.parse(data);
      //BigNumber型をcastする
      castBigNumberRecursive(retObj); 

      return retObj;
    }];

    const store = option.store;
    const router = option.router;

    axiosPlugin.interceptors.request.use(
      function (request) {
        console.log("request:");
        console.info(request);

        if (router.currentRoute.value.meta.requireAuth) {
          //認証が必要なページでのAPIリクエストは、アクセストークンを追加する
          request.data.access_token = store.state.accessToken;
        }

        store.commit("setIsNetworkError", false);

        return request;
      },
      function (error) {
        console.error(error);
        return Promise.reject(error);
      }
    );

    axiosPlugin.interceptors.response.use(
      function (response) {
        console.log("response");
        console.info(response);
        return response;
      },
      function (error) {
        if (!error.response) {
          // network error
          console.log("network error");
          store.commit("setIsNetworkError", true);
        } else {
          if (error.response.status == 404) {
            console.log("network error");
            store.commit("setIsNetworkError", true);
          }

          console.error(error);
          return Promise.reject(error);
        }
      }
    );

    app.provide("axios", axiosPlugin);
  },
};
