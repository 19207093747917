<template>
  <div>
    <div class="white-block">
      <div class="message">
        <div class="message-first-half">
          <span>エムランチをご利用いただき</span>
          <span>ありがとうございます。</span>
        </div>

        <div class="message-second-half">
          <span>ご利用いただく前に</span>
          <span>お客様名のご確認をお願いします。</span>
        </div>
      </div>

      <div class="customer-name">
        <div class="label">
          お客様名
        </div>

        <div class="name">
          　{{ userName }}　
        </div>
      </div>
    </div>
    <div class="colored-block">
      <div class="confirm-message">
        <span>上記お客様名で</span>
        <span>間違いありませんか？</span>
      </div>

      <div class="btn-block">
        <button @click="moveToPreAuth">
          いいえ
        </button>

        <button @click="auth">
          <span v-if="!isLoading"> はい</span>
          <loader v-if="isLoading" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Loader from "../components/Loader.vue";

export default {
  components: {
    Loader,
  },
  props: {
    userCd: {
      type: String,
      required: true,
      default: "",
    },
    userName: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    let idToken = ref(""),
      isLoading = ref(false);

    let moveToPreAuth = () => {
      router.push({ name: "PreAuth" });
    };

    if (props.userCd == "" || props.userName == "") {
      //パラメーターが渡されていない→preAuthへ遷移
      moveToPreAuth();
    }

    liff
      .ready
      .then(() => {
        idToken = liff.getIDToken();
      });

    const axios = inject("axios");

    let auth = () => {
      //ローダー表示
      isLoading.value = true;

      //do_authにリクエスト
      axios
        .post("/auth/do_auth", {
          company_cd: store.state.companyCd,
          customer_cd: props.userCd,
          line_auth_id: idToken,
        })
        .then((response) => {
          if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
            //成功→アクセストークンを保存して、ホーム画面へ遷移
            store.commit("setAccessToken", response.data.body.access_token);
            router.push({ name: "Home" });
          } else {
            //do_auth失敗時→エラー画面へ遷移
            router.push({ name: "Error" });
          }
        });
    };

    return {
      moveToPreAuth,
      auth,
      idToken,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.white-block {
  height: 60vh;

  .message {
    width: fit-content;
    padding-top: 2rem;
    margin: auto;

    span {
      display: block;
      text-align: left;
      letter-spacing: 0.2rem;
    }

    .message-first-half {
      margin-bottom: 2rem;
    }
  }

  .customer-name {
    height: 40vh;

    padding-top: calc(20vh - 4.5rem);

    min-width: 60vw;
    width: fit-content;
    margin: auto;

    .label {
      height: 1.5rem;
      padding-left: 5%;
      font-size: 0.8rem;
      text-align: left;
    }

    .name {
      border-bottom: 1px solid black;

      height: 2.5rem;
      font-size: 1.5rem;
    }
  }
}

.colored-block {
  height: 40vh;
  background-color: $color-orange;

  .confirm-message {
    height: 25vh;
    padding-top: 12.5vh;
    text-align: left;
    width: fit-content;
    margin: auto;

    span {
      display: block;
      color: $color-white;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
      line-height: 2.5rem;
      transform: translate(0, -100%);
    }
  }

  .btn-block {
    display: flex;
    justify-content: space-evenly;

    button {
      width: 7rem;
      height: 3rem;

      background-color: $color-white;
      border: 2px solid black;
      border-radius: 0.5rem;

      color: black;
      font-size: 1.2rem;
    }
  }
}
</style>
