// HOME画面

<template>
  <div class="home">
    <div class="welcome-message">
      {{ customer_name }}さん
    </div>
    <div class="welcome-message">
      {{ appName }}へようこそ！
    </div>

    <div class="calendar">
      <div class="date">
        {{ todayDate }}
      </div>
      <div class="day_of_week">
        {{ todayDayOfWeek }}
      </div>
    </div>

    <transition>
      <div
        v-if="isInitialized"
        class="info"
        appear
      >
        <todays-order-status
          :order-status="orderStatus"
          :is-order-accepting="isOrderAccepting"
          :is-no-order-day="isNoOrderDay"
        />
        <todays-order-details :order-details="todaysOrderDetails" />
        <div
          v-if="menuUrl != ''"
          class="info-menu"
          @click="openMenu"
        >
          <span class="label">日替わり弁当メニュー表</span>
          <svg-image
            class="icon"
            name="external_link"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import liff from "@line/liff";
import { inject } from "vue";
import { useStore } from "vuex";
import { ref, computed } from "@vue/reactivity";
import SvgImage from "../components/SvgImage.vue";
import TodaysOrderStatus from "../components/TodaysOrderStatus.vue";
import TodaysOrderDetails from "../components/TodaysOrderDetails.vue";
import {createDateWithOutTime} from"../util/helper";

export default {
  components: { TodaysOrderStatus, TodaysOrderDetails, SvgImage },

  setup(props) {
    let orderStatus = ref(-1),
      isNoOrderDay = ref(false),
      isOrderAccepting = ref(false),
      todaysOrderDetails = ref([]),
      customer_name = ref(""),
      menuUrl = ref(""),
      isInitialized = ref(false);

    let store = useStore();

    const axios = inject("axios");
    axios.post("/home/get_home_info", {}).then((response) => {
      //結果を画面にバインド
      if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
        customer_name.value = response.data.body.home.customer_name;
        orderStatus.value = response.data.body.home.order_status;
        //注文不可日FLG
        //no_order_flgは1:注文可/2:注文不可
        isNoOrderDay.value = response.data.body.home.no_order_flg == 2;
        //注文受付中FLG
        //order_limit_flgは1:注文可/2:注文不可
        isOrderAccepting.value = response.data.body.home.order_limit_flg == 1;
        //注文詳細
        todaysOrderDetails.value = response.data.body.home.order_detail.map((detail) => {
          return {
            productName: detail.product_name,
            quantity: detail.quantity,
          };
        });
        //メニューの外部リンク
        menuUrl.value = response.data.body.home.menu_url;

        store.commit("setToday", createDateWithOutTime(new Date(response.data.body.home.today)));

        //初期化完了
        isInitialized.value = true;
      }
    });

    /**
     * 当日の日付
     * ストアにデータが存在していればtodayの日付を表示、なければDate()の日付
     */
    let todayDate = computed(() => {
      if (store.state.today) {
        return store.state.today.getDate();
      } else {
        return new Date().getDate();
      }
    });

    /**
     * 当日の曜日 
     * ストアにデータが存在していればtodayの曜日を表示、なければDate()の曜日
     * フォーマットは"○曜日"→"月曜日"
     */
    let todayDayOfWeek = computed(() => {
      if (store.state.today) {
        return store.state.today.toLocaleDateString("ja", { weekday: "long" });
      } else {
        return new Date().toLocaleDateString("ja", { weekday: "long" });
      }
    });

    /**
     * 外部ブラウザでメニューを開く
     */
    let openMenu = () => {
      liff.openWindow({
        url: menuUrl.value,
        external: true,
      });
    };

    let appName=process.env.VUE_APP_NAME;

    return {
      appName,
      customer_name,
      orderStatus,
      isNoOrderDay,
      isOrderAccepting,
      todaysOrderDetails,
      todayDate,
      todayDayOfWeek,
      menuUrl,
      openMenu,
      isInitialized,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

.home {
  .welcome-message {
    margin: auto;
    padding-top: 3vh;
    width: fit-content;

    font-size: 1.5rem;
  }

  .calendar {
    width: 20vh;
    height: 20vh;
    margin: 5vh auto;

    .date {
      text-align: center;
      height: 70%;
      font-size: 12vh;
      letter-spacing: 1vh;
    }
    .day_of_week {
      height: 30%;
      font-size: 3vh;
      padding-top: 0.5vh;
    }
  }

  .info {
    padding: 0 10vw;
    padding-bottom: calc(#{$navbarHeight} + 3vh);

    .info-menu {
      height: 3rem;
      padding-top: 1.5rem;

      border: 2px solid $color-gray;
      color: $color-gray;

      display: flex;
      justify-content: center;

      .label {
        transform: translateY(calc(-50% - 2px));
      }

      .icon {
        display: inline-block;

        height: 3rem;
        width: 3rem;

        transform: translateY(calc(-1.5rem - 2px));

        stroke: $color-gray;
      }
    }

    &.v-enter-active{
      transition:all 0.3s ease;
    }

    &.v-enter-from{
      opacity: 0;
      transform:translateY(1rem);
    }

    &.v-enter-to{
      opacity: 1;
      transform:translateY(0);
    }

  }
}
</style>
