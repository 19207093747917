// OrderReceptionにて表示する明細

<template>
  <div class="order-row">
    <div class="product-name">
      <span>{{ productName }}</span>
    </div>
    <div class="quantity">
      <spinner-button
        v-model="local_quantity"
        :is-freezed="isFreezed"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import SpinnerButton from "./SpinnerButton.vue";
export default {
  components: { SpinnerButton },
  props: {
    /**
     * 数量変更ができるかどうか,trueの場合は数量変更不可
     */
    isFreezed: {
      type: Boolean,
      default: false,
    },

    /**
     * 商品名
     */
    productName: {
      type: String,
      required: true,
    },

    /**
     * 単価
     */
    unitPrice: {
      type: Number,
      required: true,
    },

    /**
     * 数量
     */
    quantity: {
      type: Number,
      required: true,
    },
  },

  emits: ["update:quantity"],

  setup(props, { emit }) {
    /**
     * 画面表示用の数量
     * 変更されると、数量変更のイベントを起こす
     */
    let local_quantity = computed({
      get: () => {
        return props.quantity;
      },
      set: (value) => {
        emit("update:quantity", value);
      },
    });

    return {
      local_quantity,
    };
  },
};
</script>

<style lang="scss" scoped>
.order-row {
  display: flex;
  justify-content: space-between;
  height: fit-content;

  padding: 0.5rem;

  width: 100%;
  height: 4rem;

  .product-name {
    text-align: left;
    white-space: nowrap;
    margin-right: 1.5rem;

    padding-top: 1.5rem;

    span {
      display: block;
      transform: translateY(-50%);
    }
  }
}
</style>
