// 注文履歴画面

<template>
  <div>
    <div class="header">
      <div class="year">
        {{ displayYear }}
      </div>
      <div class="month">
        <div
          class="btn btn-prev"
          @click="movePrev"
        >
          <svg-image
            class="arrow"
            name="arrow"
          />
        </div>
        <div>{{ displayMonth }}月</div>
        <div
          class="btn btn-next"
          :class="{ disabled: !canMoveNext }"
          @click="moveNext"
        >
          <svg-image
            class="arrow"
            name="arrow"
          />
        </div>
      </div>
    </div>

    <div class="history">
      <transition-group>
        <div
          v-for="(orderHistory, index) in orderHistories"
          :key="orderHistory.orderDate"
          :style="'transition-delay: calc(' + index + ' * 0.05s)'"
        >
          <order-history-card
            class="card"
            :order="orderHistory"
          />
          <div class="divider" />
        </div>
      </transition-group>

      <transition>
        <div
          v-if="isHistoryLoaded && orderHistories.length == 0"
          class="history-none"
        >
          <div
            v-if="isLoadError"
            class="error-message"
          >
            表示中にエラーが発生しました
          </div>
          <div v-else>
            この月の注文は存在しません
          </div>
        </div>
      </transition>

      <div
        v-if="isHistoryLoaded == false"
        class="loader-block"
      >
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import OrderHistoryCard from "../components/OrderHistoryCard.vue";
import SvgImage from "../components/SvgImage.vue";
import Loader from "../components/Loader.vue";
export default {
  components: { OrderHistoryCard, SvgImage, Loader },
  setup(props) {
    let selectedMonth = ref(new Date());

    /**
     * 前月に移動する
     * 選択中の月を更新し、データを再取得する
     */
    const movePrev = () => {
      let newDate = new Date(selectedMonth.value.getTime());
      newDate.setMonth(newDate.getMonth() - 1);
      selectedMonth.value = newDate;

      getOrderList();
    };

    /**
     * 次月に移動する
     * 選択中の月を更新し、データを再取得する
     */
    const moveNext = () => {
      if (canMoveNext.value == false) {
        return;
      }

      let newDate = new Date(selectedMonth.value.getTime());
      newDate.setMonth(newDate.getMonth() + 1);
      selectedMonth.value = newDate;

      getOrderList();
    };

    /**
     * 次月に移動できるか判定する
     * 当月を表示中の場合のみ不可
     */
    const canMoveNext = computed(() => {
      let now = new Date();
      if (
        selectedMonth.value.getFullYear() == now.getFullYear() &&
        selectedMonth.value.getMonth() == now.getMonth()
      ) {
        return false;
      } else {
        return true;
      }
    });

    /**
     * 現在選択中の年
     */
    const displayYear = computed(() => {
      return selectedMonth.value.getFullYear();
    });

    /**
     * 現在選択中の月
     */
    const displayMonth = computed(() => {
      return selectedMonth.value.getMonth() + 1;
    });

    const isLoadError = ref(false);
    let orderHistories = ref([]);
    let isHistoryLoaded = ref(false);
    const axios = inject("axios");

    /**
     * 注文履歴取得
     * 現在選択中の月をパラメータに、注文履歴を取得する
     */
    const getOrderList = () => {
      orderHistories.value = [];
      isHistoryLoaded.value = false;
      isLoadError.value = false;
      axios
        .post("/history/get_history_list", {
          target_year: displayYear.value,
          target_month: displayMonth.value,
        })
        .then((response) => {
          if (response.data.header.result == process.env.VUE_APP_API_RESULT_SUCCESS) {
            orderHistories.value = response.data.body.history_list.map((history) => {
              return {
                orderDate: new Date(history.order_date),
                orderStatus: history.order_status,
                orderDetails: history.order_detail.map((detail) => {
                  return {
                    productCd: detail.productCd,
                    productName: detail.product_name,
                    quantity: detail.quantity,
                    unitPrice: detail.unit_price,
                    amount: detail.amount,
                  };
                }),
                totalTax: history.total_tax,
                totalPrice: history.total_price,
              };
            });
          } else {
            isLoadError.value = true;
          }
        })
        .catch((error) => {
          isLoadError.value = true;
        })
        .finally(() => {
          isHistoryLoaded.value = true;
        });
    };

    //初回読み込み時の注文履歴取得
    getOrderList();

    return {
      orderHistories,
      selectedMonth,
      movePrev,
      moveNext,
      canMoveNext,
      displayYear,
      displayMonth,
      getOrderList,
      isHistoryLoaded,
      isLoadError,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";
@import "../assets/styles/variables";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: $color-white;

  height: $pageHeaderHeight;
  padding: 0.5rem 0;

  .year {
    font-size: 0.7rem;
  }

  .month {
    font-size: 1.2rem;
    display: flex;

    div {
      width: 3rem;
      line-height: 2rem;
    }

    .btn {
      border: none;
      background: none;
      height: 2rem;
      width: 2rem;

      .arrow {
        height: 100%;
        width: 100%;
        stroke: $color-green;
      }
      &.disabled {
        .arrow {
          stroke: $color-gray;
        }
      }

      &.btn-prev {
        margin-left: auto;
        margin-right: 1rem;

        .arrow {
          transform: rotate(180deg);
        }
      }

      &.btn-next {
        margin-left: 1rem;
        margin-right: auto;
      }
    }
  }
}

.history {
  padding-top: $pageHeaderHeight;
  padding-bottom: calc(#{$navbarHeight} + 0.5rem);

  padding-left: 5%;
  padding-right: 5%;

  min-height: 100%;

  .card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .divider {
    border-bottom: 1px solid $color-gray;
  }

  .v-enter-active {
    transition: all 0.3s ease;
  }

  .v-leave-active {
    transition-duration: 0s;
    transition-delay: 0s !important;
  }

  .v-enter-from {
    opacity: 0;
    transform: translateY(1rem);
  }

  .v-enter-to {
    opacity: 1;
    transform: translateY(0);
  }

  .history-none {
    height: 3rem;
    line-height: 3rem;
    margin-top: calc(50vh - (#{$navbarHeight} / 2));
    transform: translateY(calc(-1.5rem - #{$pageHeaderHeight}));

    font-size: 1.2rem;
    font-weight: bold;
  }

  .loader-block {
    width: 5rem;
    height: 5rem;
    margin: auto;

    margin-top: calc(50vh - (#{$navbarHeight} / 2));
    transform: translateY(calc(-2.5rem - #{$pageHeaderHeight}));
  }
}
</style>
