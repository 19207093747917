// 決済中のエラーモーダル

<template>
  <dialog-modal :is-show="isShowModal">
    <div class="payment-error">
      <svg-image
        class="icon-error"
        name="error"
      />
      <template v-if="isCanceled">
        <!-- ユーザーによるキャンセル時の表示 -->
        <div class="head">
          お支払いをキャンセルしました
        </div>
      </template>

      <template v-else>
        <!-- 汎用エラー表示 -->
        <div class="head">
          エラーが発生しました
        </div>
        <div class="message">
          決済中にエラーが発生しました
        </div>
        <div class="message">
          しばらくしてから再度行って下さい
        </div>
      </template>

      <pill-button
        class="btn-confirm gray small"
        @click="closeModal"
      >
        確認
      </pill-button>
    </div>
  </dialog-modal>
</template>

<script>
import { ref } from "@vue/reactivity";
import DialogModal from "./DialogModal.vue";
import SvgImage from "./SvgImage.vue";
import PillButton from "./PillButton.vue";
export default {
  components: { DialogModal, SvgImage, PillButton },

  emits: ["order-end"],

  setup(props, { emit }) {
    const isShowModal = ref(false);
    const isCanceled = ref(false);

    /**
     * モーダルを開くメソッド
     * ※親コンポーネントから呼び出す
     * isPaymentCanceledにtrueを渡すことで支払いキャンセル時のモーダル表示とする
     */
    const openModal = (isPaymentCanceled = false) => {
      isShowModal.value = true;
      isCanceled.value = isPaymentCanceled
    };

    /**
     * モーダルを閉じるメソッド
     * ※親コンポーネントから呼び出す
     */
    const closeModal = () => {
      isShowModal.value = false;

      //注文手続きの終了を親に通知する
      emit("order-end");
    };

    return {
      isShowModal,
      openModal,
      closeModal,
      isCanceled,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors";

.payment-error {
  width: fit-content;
  margin: auto;

  .icon-error {
    width: 3rem;
    height: 3rem;

    fill: $color-red;
    stroke: $color-red;

    margin: auto;
    margin-bottom: 1rem;
  }

  .head {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 1rem auto;
  }

  .message {
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }

  .btn-confirm {
    margin-top: 1rem;
  }
}
</style>
